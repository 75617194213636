import {
  FETCH_DRIVER,
  FETCH_CUSTOMER,
  FETCH_TRIPS,
  FETCH_LOCATION,
  FETCH_CITY_DEALER,
  CLEAR_OBJECT,
  FETCH_FRANCHISE,
  FETCH_ADMINS,
} from "../actions/types";

const initialState = {
  drivers: {},
  customers: {},
  trips: {},
  cityDealer: {},
  franchise: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINS:
      return { ...state, admins: { ...state.admins, ...action.payload } };
    case FETCH_FRANCHISE:
      return {
        ...state,
        franchise: { ...state.franchise, ...action.payload },
      };
    case FETCH_DRIVER:
      return {
        ...state,
        drivers: { ...state.drivers, ...action.payload },
      };
    case FETCH_CITY_DEALER:
      return {
        ...state,
        cityDealer: { ...state.cityDealer, ...action.payload },
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        customers: { ...state.customers, ...action.payload },
      };
    case FETCH_TRIPS:
      return {
        ...state,
        trips: { ...state.trips, ...action.payload },
      };
    case FETCH_LOCATION:
      const key = Object.keys(action.payload)[0];
      const { lat, long } = action.payload[key];
      return {
        ...state,
        drivers: {
          ...state.drivers,
          [key]: {
            ...state.drivers[key],
            position: {
              lat,
              long,
            },
          },
        },
      };
    case CLEAR_OBJECT:
      return {
        ...state,
        [action.payload]: {},
      };
    default:
      return state;
  }
};

export default reducer;
