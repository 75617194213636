import React, { useEffect } from "react";
import { fetchLocation } from "../actions";
import { connect } from "react-redux";
import MapContainer from "./MapContainer";
import { DEFAULT_FALLBACK_GLOBAL_LOCATION } from "../constants";

function CustomerDetails({ name, email, phoneNumber, fetchLocation, position, driverKey }) {
  useEffect(() => {
    const interval = setInterval(() => {
      fetchLocation(driverKey);
    }, 5000);

    return () => clearInterval(interval);
  }, [driverKey, fetchLocation]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ top: "5em", overflowY: "auto", marginLeft: "2em" }}>
        <h2>Driver Information</h2>
        <div style={{ marginBottom: "1rem"}}>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Email:</strong> {email ? email : "No Info"}</p>
          <p><strong>Phone Number:</strong> {phoneNumber}</p>
        </div>
        <div style={{ height: "300px" }}>
            <MapContainer lat={position.lat} lng={position.long} /> 
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { driver } = state;
  if (!driver || !driver.name) {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      position: { lat: 0, long: 0 }
    };
  }

  const { name, email, phoneNumber, position } = driver;

  return {
    name: name || '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    position,
  };
};

const mapDispatchToProps = {
  fetchLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
