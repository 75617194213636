import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import axios from 'axios';
import './cityDealer.css';
import { STATES, BASE_URL } from '../constants';
import { createCityDealer } from '../actions/axios';
import { fetchCityDealer, deleteCityDealer } from '../actions';

const CityDealer = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [currentUUID, setCurrentUUID] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const uuid = user.uid;
        setCurrentUUID(uuid);
        console.log('Current user UUID:', uuid);
        // You can perform any additional actions with the UUID here
      }
    });
  }, []);

  const handleSignup = async () => {
    if (!name || !email || !password || !state) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await auth.createUserWithEmailAndPassword(email, password);
      const user = response.user;

      // Pass the UUID to the backend
      await createCityDealer(user, name, email, password, state, user._delegate.uid);

      console.log('User created successfully');
      alert('New user has been created');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        alert('The email address is already in use by another account.');
      } else {
        console.error('Failed to create user:', error);
        alert(error.message);
      }
    }
  };

  const handleForgotPassword = () => {
    if (!email) {
      alert('Please enter your email to reset the password');
      return;
    }

    auth.sendPasswordResetEmail(email)
      .then(() => {
        alert('Password reset email sent. Please check your email inbox.');
      })
      .catch((error) => {
        console.error('Failed to send password reset email:', error);
        alert('An error occurred while sending the password reset email.');
      });
  };

  return (
    <div className='container'>
      <h4>City Dealer sign up</h4>
      <input
        type='text'
        placeholder='Name'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type='email'
        placeholder='Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type='password'
        placeholder='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <select value={state} onChange={(e) => setState(e.target.value)}>
        <option value=''>Select State</option>
        {STATES.map((state, index) => (
          <option key={index} value={state}>
            {state}
          </option>
        ))}
      </select>
      <button onClick={handleSignup}>Sign up</button>
      <p className='forgot-password' onClick={handleForgotPassword}>
        Forgot Password?
      </p>
    </div>
  );
};

export default CityDealer;
