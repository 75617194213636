import React, { useEffect, useState } from "react";
import UpdateCarRates from "./UpdateCarRates";
import { axiosInstanceProxy } from "../actions/axios"; // Axios instance for API calls
import { STATES } from "../constants";
import "./UpdateCarRates.css";

const ManageCarRates = () => {
  const [allRates, setAllRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedState, setSelectedState] = useState(""); // Added state to store the selected state

  // Fetch all rates for all states in one API call
  useEffect(() => {
    const fetchAllRates = async () => {
      try {
        const response = await axiosInstanceProxy.get("admin/getAllRates");
        setAllRates(response.data); // Assuming response.data contains state-wise rates
        setLoading(false);
      } catch (error) {
        console.error("Error fetching rates:", error);
        setMessage("Error fetching rates.");
        setLoading(false);
      }
    };

    fetchAllRates();
  }, []);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value); // Update the selected state
  };

  return (
    <div>
      <h2>Manage Car Rates by State</h2>
      {loading ? (
        <p>Loading rates...</p>
      ) : (
        <div className="container">
          <div className="dropdown-container">
            <label htmlFor="state-select">Select State:</label>
            <select
              id="state-select"
              value={selectedState}
              onChange={handleStateChange}
            >
              <option value="">--Select a State--</option>
              {STATES.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          {selectedState && (
            <UpdateCarRates
              stateName={selectedState}
              rates={allRates[selectedState]} // Pass the rates for the selected state
            />
          )}
        </div>
      )}
     
       
    </div>
  );
};

export default ManageCarRates;
