import React, { useState, useEffect } from 'react';
import { axiosInstanceProxy } from '../actions/axios';

const UpdateCarRates = ({ stateName, rates }) => {
  const [mini, setMini] = useState('');
  const [sedan, setSedan] = useState('');
  const [suv, setSuv] = useState('');
  const [message, setMessage] = useState('');

  // Update the form fields when the state or rates change
  useEffect(() => {
    if (rates) {
      setMini(rates.mini || '');
      setSedan(rates.sedan || '');
      setSuv(rates.suv || '');
    }
  }, [rates, stateName]);

  const updateRates = async () => {
    try {
      await axiosInstanceProxy.put(`admin/updateRates/${stateName}`, {
        mini: Number(mini),
        sedan: Number(sedan),
        suv: Number(suv),
      });
      setMessage('Rates updated successfully!');
    } catch (error) {
      console.error('Error updating rates:', error);
      setMessage('Error updating rates.');
    }
  };

  return (
    <div className="update-rates-container">
      <h2>Update Rates for {stateName}</h2>
      <label>Mini:</label>
      <input
        type="number"
        value={mini}
        onChange={(e) => setMini(e.target.value)}
        placeholder="Mini rate"
      />
      <label>Sedan:</label>
      <input
        type="number"
        value={sedan}
        onChange={(e) => setSedan(e.target.value)}
        placeholder="Sedan rate"
      />
      <label>SUV:</label>
      <input
        type="number"
        value={suv}
        onChange={(e) => setSuv(e.target.value)}
        placeholder="SUV rate"
      />
      <button onClick={updateRates}>Update Rates</button>
      <p className={message.includes('successfully') ? 'success' : 'error'}>
        {message}
      </p>
    </div>
  );
};

export default UpdateCarRates;
