import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import { connect } from "react-redux";
import Loading from "./Loading/Loading";
import { getAdminsAction } from "./actions";
import './PasswordReset.css'; // Import your CSS file

const PasswordReset = ({ getAdminsAction, admins }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAdminsAction()
      .then(() => setLoading(false))
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  }, [getAdminsAction]);

  const handlePasswordReset = async () => {
    if (!email) {
      setError("Please select an email.");
      return;
    }

    try {
      await auth.sendPasswordResetEmail(email);
      setMessage("Password reset email sent. Please check your inbox.");
      setError("");
    } catch (error) {
      setError("Failed to send password reset email: " + error.message);
    }
  };

  return (
    <div className="password-reset-container">
      {loading ? (
        <Loading />
      ) : (
        <div className="password-reset-content">
          <label htmlFor="email-dropdown">Select Email:</label>
          <select
            id="email-dropdown"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          >
            <option value="" disabled>Select an email</option>
            {admins?.data?.map((emailOption, index) => (
              <option key={index} value={emailOption}>
                {emailOption}
              </option>
            ))}
          </select>
          <button className="reset-button" onClick={handlePasswordReset}>
            Send Password Reset Link
          </button>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { admins: data } = state?.root;
  return {
    admins: data,
  };
};

const mapDispatchToProps = {
  getAdminsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
