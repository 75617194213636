import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import './createfranchise.css'
import { STATES } from '../constants';
import { createFranchise } from '../actions/axios';

const  statesOptions = STATES;

const CreateUserWithFranchiseClaim = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedState, setSelectedState] = useState(statesOptions[0]);

  const handleCreateUser = async () => {
    if (!name || !email || !password || !selectedState) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const response = await auth.createUserWithEmailAndPassword(email, password);
      const user = response.user;
    await createFranchise(user, name, email, password, selectedState, user._delegate.uid)
      console.log('User created successfully');
      alert('New user has been created');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        alert('The email address is already in use by another account.');
      } else {
        console.error('Failed to create user:', error);
        alert(error.message);
      }
    }
  };

  return (
    <div className='createfranchise'>
      <h2>Franchise Sign up</h2>
      <div>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <select
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
        >
          {statesOptions.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
        <button onClick={handleCreateUser}>Create User</button>
      </div>
    </div>
  );
};

export default CreateUserWithFranchiseClaim;
