import React, { useState } from "react";
import "./TripDetails.css";

function TripDetails(props) {
  const trip = props.trip;
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  const handleClose = () => {
    props.setSelectedTrip(null);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(Number(event.target.value));
  };

  const handleSubmit = () => {
    // Perform necessary actions with the feedback and rating data
    // For example, you can send them to an API or update the state of the parent component
    console.log("Feedback:", feedback);
    console.log("Rating:", rating);

    // Reset the feedback and rating state variables
    setFeedback("");
    setRating(0);
  };

  return (
    <div className="trip-details-container">
      <button className="close-button" onClick={handleClose}>
        X
      </button>
      <h2 className="trip-details-header">{trip ? trip.title || "" : ""}</h2>


      <form>
        <table className="trip-details-table">
          <tbody>
            <tr>
              <td>Body:</td>
              <td>{trip ? trip.body || "" : ""}</td>
            </tr>
            <tr>
              <td>Pick-up location:</td>
              <td>{(trip && trip["pick-up"]?.location) || ""}</td>
            </tr>
            <tr>
              <td>Destination location:</td>
              <td>{trip?.destination?.location || ""}</td>
            </tr>
            <tr>
              <td>Driver:</td>
              <td>{trip?.driver ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td>Driver name:</td>
              <td>{trip?.driver_info?.name || ""}</td>
            </tr>
            <tr>
              <td>Driver phone number:</td>
              <td>{trip?.driver_info?.phoneNumber || ""}</td>
            </tr>
            <tr>
              <td>Driver rating:</td>
              <td>{trip?.driver_info?.rating || ""}</td>
            </tr>
            <tr>
              <td>Vehicle type:</td>
              <td>{trip?.driver_info?.Car || ""}</td>
            </tr>
            <tr>
              <td>Driver vehicle number:</td>
              <td>{trip?.driver_info?.vehicleNumber || ""}</td>
            </tr>
            <tr>
            <td>Phone number:</td>
            <td>{trip && trip.phoneNumber}</td>
          </tr>
            <tr>
              <td>Feedback:</td>
              <td>
                <textarea
                  value={feedback}
                  onChange={handleFeedbackChange}
                  className="feedback-textarea"
                />
              </td>
            </tr>
            <tr>
              <td>Rating:</td>
              <td>
                <div className="rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <label key={star}>
                      <input
                        type="radio"
                        name="rating"
                        value={star}
                        checked={rating === star}
                        onChange={handleRatingChange}
                      />
                      <span className={`star ${rating >= star ? "highlighted" : ""}`}>&#9733;</span>
                    </label>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default TripDetails;
