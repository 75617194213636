import React, { Component } from "react";

class MyMap extends Component {
  state = {
    center: { lat: this.props.lat, lng: this.props.lng },
    zoom: 12
  };

  componentDidMount() {
    const map = new window.google.maps.Map(this.mapRef, {
      center: this.state.center,
      zoom: this.state.zoom
    });

    new window.google.maps.Marker({
      position: { lat: this.props.lat, lng: this.props.lng },
      map: map,
      title: "Hello World!",
      icon: {
        url: `/marker.png`,
        size: new window.google.maps.Size(50, 50),
        scaledSize: new window.google.maps.Size(50, 50)
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.setState({
        center: { lat: this.props.lat, lng: this.props.lng }
      });

      const map = new window.google.maps.Map(this.mapRef, {
        center: { lat: this.props.lat, lng: this.props.lng },
        zoom: this.state.zoom
      });

      new window.google.maps.Marker({
        position: { lat: this.props.lat, lng: this.props.lng },
        map: map,
        title: "Hello World!",
        icon: {
          url: `/marker.png`,
          size: new window.google.maps.Size(50, 50),
          scaledSize: new window.google.maps.Size(50, 50)
        }
      });
    }
  }

  render() {
    return (
      <div
        ref={ref => {
          this.mapRef = ref;
        }}
        style={{ width: "100%", height: "400px" }}
      />
    );
  }
}

export default MyMap;
