import React, { useEffect, useState, useRef } from 'react';
import { fetchFranchise, deleteCityDealer } from '../actions';
import './display.css';
import { connect } from 'react-redux';

const Franchise = ({ fetchFranchise, deleteCityDealer, franchise }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const popupRef = useRef();

  useEffect(() => {
    fetchFranchise();
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleClickOutsidePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopup);
    };
  }, []);

  const renderfranchise = () => {
    const dealers = Object.entries(franchise || {});

    if (dealers.length === 0) {
      return <p>No dealers available.</p>;
    }

    const sortedDealers = Object.values(dealers)
      .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
      .reverse();

    const mostRecentDealer = sortedDealers[0][1];

    return (
      <div className="franchise">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>State</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedDealers.map(([key, item]) => (
              <tr key={key} onClick={() => handleItemClick(item)}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.state}</td>
                {/* <td>
                  <button onClick={(e) => handleDeleteDealer(e, key)}>Delete Franchise</button>
                </td> */}
                {key === sortedDealers[0][0] && (
                  <td className="notification-bar">New Dealer: {mostRecentDealer.name}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {selectedItem && (
          <div className="popup" ref={popupRef}>
            <h3>Selected Item:</h3>
            <p>Name: {selectedItem.name}</p>
            <p>Email: {selectedItem.email}</p>
            <p>State: {selectedItem.state}</p>
            {/* Add more data fields as needed */}
          </div>
        )}
      </div>
    );
  };

  const handleDeleteDealer = (e, key) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this city-dealer?')) {
      deleteCityDealer(key);
      setSelectedItem(null);
    }
  };

  return <>{renderfranchise()}</>;
};

export default connect(
  (state) => {
    return { franchise: state.root.franchise };
  },
  { fetchFranchise, deleteCityDealer }
)(Franchise);
