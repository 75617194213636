import React, { useEffect, useState } from "react";
import TripDetails from "./TripDetails";
import { fetchTrips, fetchDrivers } from "../actions";
import { connect } from "react-redux";
import "./Trips.css";
import Loading from "../Loading/Loading";

function TripList(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTrips, setFilteredTrips] = useState(props.trips);

  useEffect(() => {
    props.fetchDrivers().catch((e) => {
      setErrorMessage(e.response.data.error);
    });
  }, []);

  useEffect(() => {
    props.fetchTrips(props.driverIds).then(() => {
      
    });
  }, []);
  useEffect(()=>{
    
    if(Object.keys(props.trips).length>0){
    setFilteredTrips(props.trips)
    setIsLoading(false);
  }
  },[props.trips])

  const handleClick = (tripId) => {
    setSelectedTripId(tripId);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterTrips(e.target.value);
  };

  const filterTrips = (searchTerm) => {
    const trips = props.trips || {};

    const filteredTrips = {};

    Object.entries(trips).forEach(([tripId, trip]) => {
      if (
        trip.title &&
        trip.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        filteredTrips[tripId] = trip;
      }
    });

    setFilteredTrips(filteredTrips);
  };

  const renderTripList = () => {
    return (
      <table className="trip-list-table">
        <thead>
          <tr>
            <th>Trip Title</th>
            <th>Pick-up Location</th>
            <th>Destination Location</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(filteredTrips).map(([tripId, trip], index) => (
            <tr key={tripId}>
              <td>{trip.title}</td>
              <td>{trip["pick-up"]?.location}</td>
              <td>{trip.destination?.location}</td>
              <td>
                {index === 0 ? (
                  <div className="notification most-recent">
                    Most Recent Trip
                  </div>
                ) : (
                  <div className="notification">
                    {trip.paid && (
                      <span className="small-notification paid">Paid</span>
                    )}
                    {trip.completed && (
                      <span className="small-notification completed">
                        Completed
                      </span>
                    )}
                  </div>
                )}
              </td>
              <td>
                <button
                  className="view-details-button"
                  onClick={() => handleClick(tripId)}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {errorMessage ? (
        errorMessage
      ) : (
        <>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Trips"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {selectedTripId ? (
                <TripDetails
                  trip={filteredTrips[selectedTripId]}
                  selectedTrip={selectedTripId}
                  setSelectedTrip={setSelectedTripId}
                />
              ) : (
                <div className="trip-list-container">
                  <div className="trip-list-header">List of Trips</div>
                  {renderTripList()}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const { trips, drivers } = state.root;
  return {
    trips,
    driverIds: Object.keys(drivers),
  };
};

const mapDispatchToProps = {
  fetchTrips,
  fetchDrivers,
};

export default connect(mapStateToProps, mapDispatchToProps)(TripList);
