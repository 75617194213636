import React from 'react';

const RatingDetails = ({ rating }) => {
  const getRandomRating = () => {
    const minRating = 3;
    const maxRating = 5;
    return Math.floor(Math.random() * (maxRating - minRating + 1) + minRating);
  };

  const renderStarIcons = (rating) => {
    const maxStars = 5;
    const fullStar = '★';
    const emptyStar = '☆';
    const renderedStars = [];

    for (let i = 1; i <= maxStars; i++) {
      if (i <= rating) {
        renderedStars.push(<span key={i} style={{ color: '#f7d94e', marginRight: '0.25rem' }}>{fullStar}</span>);
      } else {
        renderedStars.push(<span key={i} style={{ color: '#f7d94e', marginRight: '0.25rem' }}>{emptyStar}</span>);
      }
    }

    return renderedStars;
  };

  return (
    <div style={{ margin: '1rem', padding: '1rem', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
      <h2>Rating Details</h2>
      {rating && Object.keys(rating).length > 0 ? (
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {Object.keys(rating).map((key) => (
            <li key={key} style={{ marginBottom: '1rem' }}>
              <strong>{rating[key]?.customerName}</strong>
              <br />
              Rating: {rating[key]?.rating ? (
                renderStarIcons(rating[key].rating)
              ) : (
                renderStarIcons(getRandomRating())
              )}
              <br />
              Description: {rating[key]?.description}
            </li>
          ))}
        </ul>
      ) : (
        renderStarIcons(getRandomRating())
      )}
    </div>
  );
};

export default RatingDetails;
