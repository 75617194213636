import React, { useEffect, useState } from 'react';
import './WelcomePage.css';
import { auth } from '../firebase';
const WelcomePage = () => {
  const [animationDone, setAnimationDone] = useState(false);
  const [email, setEmail] = useState(undefined)
  const [name, setName] = useState(undefined)
  useEffect(() => {
    setTimeout(() => {
      setAnimationDone(true);
    }, 6000);
  }, []);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user._delegate)
        setName(user._delegate.displayName)
        setEmail(user._delegate.email)
        //set user email to component here
      } else {
    
      }
    });

    // Clean up the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className={`welcome-page ${animationDone ? 'animation-done' : ''}`}>
      <h1 className="welcome-heading">Welcome {name || email} to Book-My-ETaxi</h1>
      <p className="welcome-description">Book your ride with us today and experience the best taxi service in town!</p>
    </div>
  );
};

export default WelcomePage;