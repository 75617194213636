import React, { useState } from 'react';
import Header from './Header';
import './Sidebar.css';
import ChildSidebar from './ChildSidebar';

const Sidebar = ({ enableSideBar, setEnableSideBar, currentClaim }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);

    // add/remove "sidebar-open" class to the <body> element
    document.body.classList.toggle('sidebar-open', !isOpen);
  };

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} enableSideBar={enableSideBar} isOpen={isOpen} />
      {enableSideBar && (
        <div className="sidebar-container">
          <ChildSidebar currentClaim={currentClaim} isOpen={isOpen} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;

