module.exports = {
    ID_TOKEN_KEY: 'idToken',
    EXPIRATION_TIME_KEY: 'expirationTime',
    EXPIRY_DURATION: 3,
    CURRENT_CLAIM: 'currentClaim',
    FIREBASE_CONFIG:{
        apiKey: "AIzaSyB9veCDeodL87QObk_JXfVvdNvG-JQKafU",
        authDomain: "book-my-etaxi.firebaseapp.com",
        projectId: "book-my-etaxi",
        storageBucket: "book-my-etaxi.appspot.com",
        messagingSenderId: "709692300821",
        appId:"1:709692300821:web:c1a98ddd1a2730a229381c"
      },
    BASE_URL:'https://bmet.pro/api',
    DEFAULT_FALLBACK_GLOBAL_LOCATION:{
      lat:20,
      long:20
    },
    STATES: [
      "andhra pradesh",
      "arunachal pradesh",
      "assam",
      "bihar",
      "chandigarh",
      "chhattisgarh",
      "delhi",
      "goa",
      "gujarat",
      "haryana",
      "himachal pradesh",
      "jharkhand",
      "karnataka",
      "kerala",
      "madhya pradesh",
      "maharashtra",
      "manipur",
      "meghalaya",
      "mizoram",
      "nagaland",
      "odisha",
      "punjab",
      "rajasthan",
      "sikkim",
      "tamil nadu",
      "telangana",
      "tripura",
      "uttar pradesh",
      "uttarakhand",
      "west bengal"
    ]
}
