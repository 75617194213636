import React, { useState, useEffect } from 'react';
// import { storage } from '../firebase';
import './Documentdetails.css';

function DocumentDetails({ documents, selectedKey }) {
  const [imageUrls, setImageUrls] = useState({});
  const [zoomedImage, setZoomedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const promises = Object.entries(documents).map(([key, value]) => {
          if (value) {
            const imageUrl = `https://firebasestorage.googleapis.com/v0/b/book-my-etaxi.appspot.com/o/images%2F${selectedKey}%2F${key}.jpg?alt=media`;
            return Promise.resolve([key, imageUrl]);
          }
          return Promise.resolve([key, null]);
        });
        const results = await Promise.all(promises);
        const imageUrls = Object.fromEntries(results);
        setImageUrls(imageUrls);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImages();
  }, [documents, selectedKey]);

  const handleStatusChange = (key, newStatus) => {
    // Handle status change logic here
    console.log(`Changing status of document ${key} to ${newStatus}`);
  };

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const handleZoomedImageClose = () => {
    setZoomedImage(null);
  };
  const docNames = {
    "aadharCard":'Aadhar Card',
    "addressProof":'Address Proof',
    "driverLicence":"Driver License",
    "fitnessCertificate":'Fitness Certificate',
    "identityDetail":'Identity Detail',
    "panCard":'Pan Card',
    "pollutionCertificate":'Pollution Certificate',
    "rcCertificate": 'RC Certificate',
    "vehcileAudit":'Vehicle Audit',
    "vehiclePermit":'Vehicle Permit'
  }
  return (
    <div>
      <h2>Document Details</h2>
      <table>
        <thead>
          <tr>
            <th>Document</th>
            <th>Image</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(documents || {}).map(([key, value]) => (
            <tr key={key}>
              <td>
                <b>{docNames[key]}</b>
              </td>
              <td>
                {value && imageUrls[key] && (
                  <div
                    onClick={() => handleImageClick(imageUrls[key])}
                    className="document-image"
                  >
                    <img
                      src={imageUrls[key]}
                      alt={`Image for ${key}`}
                      className="zoomable-image"
                    />
                  </div>
                )}
              </td>
              <td>
                {value ? (
                  <div className="status-container">
                    {imageUrls[key] ? (
                      <span className="status approved">Approved</span>
                    ) : (
                      <span className="status not-approved">Not Approved</span>
                    )}
                    <select
                      value={value ? 'submitted' : 'pending'}
                      onChange={(e) => handleStatusChange(key, e.target.value)}
                    >
                      <option value="submitted">Submitted</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                ) : (
                  <span className="status not-approved">Not Approved</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {zoomedImage && (
        <div className="zoomed-image-overlay" onClick={handleZoomedImageClose}>
          <img src={zoomedImage} alt="Zoomed Image" className="zoomed-image" />
        </div>
      )}
    </div>
  );
}

export default DocumentDetails;
