import React from 'react';

const BookingHistory = ({ history }) => {
  if (!history) {
    return <p>Loading booking history...</p>;
  }

  if (Object.keys(history).length === 0) {
    return <p>No booking history available.</p>;
  }

  return (
    <div style={{ backgroundColor: '#844bb1', color: 'white' }}>
      <h2 style={{ textDecoration: 'underline' }}>Booking History</h2>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Pickup Location</th>
            <th>Destination Location</th>
            <th>Distance (in km)</th>
            <th>Price</th>
            <th>Date and Time</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(history).map((key) => (
            <tr key={key}>
              <td style={{ textAlign: 'center' }}>{history[key]?.customerName || '-'}</td>
              <td style={{ textAlign: 'center' }}>{history[key]?.pickUpLocation || '-'}</td>
              <td style={{ textAlign: 'center' }}>{history[key]?.destinationLocation || '-'}</td>
              <td style={{ textAlign: 'center' }}>{history[key]?.distance || '-'}</td>
              <td style={{ textAlign: 'center' }}>{history[key]?.price || '-'}</td>
              <td style={{ textAlign: 'center' }}>{history[key]?.dateTime || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingHistory;
