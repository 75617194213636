import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import Loading from "../Loading/Loading";
import { connect } from "react-redux";
import { deleteDriverAction, fetchDrivers } from "../actions";
import "./static.css";

const Driver = ({ drivers, fetchDriver, deleteDriverAction }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchDriver().then(() => {
      setIsLoading(false);
    }).catch((e)=>{
      
      setErrorMessage(e.response.data.error)
    });
  }, []);

  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);

  const handleDocumentSubmit = (driverId) => {
    console.log("Submitting documents for driver with ID:", driverId);
  };

  const addButtonStyle = {
    background: "#844bb1",
    color: "white",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    cursor: "pointer",
    marginLeft: "0.5rem",
  };
  const deleteButtonStyle = {
    background: "white",
    color: "#844bb1",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    cursor: "pointer",
    marginLeft: "1rem",
    float: "right",
  };

  const handleMoreClick = (driver, id) => {
    setSelectedDriver(driver);
    setSelectedKey(id);
  };

  const handleDeleteDriver = (driverId) => {
    setDeleteConfirmation(true);
    setDriverToDelete(driverId);
  };

  const confirmDelete = async () => {
    await deleteDriverAction(driverToDelete);
    setDeleteConfirmation(false);
  };

  const cancelDelete = () => {
    setDeleteConfirmation(false);
  };


  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thStyle = {
    padding: "1rem",
    textAlign: "center",
    backgroundColor: "#f0f0f0",
    paddingLeft: "1rem",
  };

  const tdStyle = {
    padding: "1rem",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
  };

  return (
    <>
    {errorMessage?errorMessage: <> {isLoading ? (
        <Loading />
      ) : (
        <>
          {selectedDriver ? (
            <Dashboard
              selectedKey={selectedKey}
              customer={selectedDriver}
              setSelectedDriver={setSelectedDriver}
            />
          ) : (
            <div className="driver-list">
              <h2>Driver List</h2>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Name</th>
                    <th style={thStyle}>Email</th>
                    <th style={thStyle}>Phone Number</th>
                    <th style={thStyle}>Country</th>
                    <th style={thStyle}>Delete driver</th>
                    <th style={thStyle}></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(drivers || {}).map(([id, driver]) => (
                    <tr key={id}>
                      <td style={tdStyle}>{driver.name}</td>
                      <td style={tdStyle}>{driver.email || "No info"}</td>
                      <td style={tdStyle}>
                        {driver.phoneNumber || "No info"}
                      </td>
                      <td style={tdStyle}>{driver.country || "India"}</td>
                      <td style={tdStyle}>
                        {deleteConfirmation && driverToDelete === id ? (
                          <div>
                            <button
                              style={{ ...deleteButtonStyle, marginRight: '0.5rem' }}
                              onClick={confirmDelete}
                            >
                              Confirm
                            </button>
                            <button
                              style={deleteButtonStyle}
                              onClick={cancelDelete}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            style={deleteButtonStyle}
                            onClick={() => handleDeleteDriver(id)}
                          >
                            Delete driver
                          </button>
                        )}
                      </td>
                      <td style={tdStyle}>
                        <button
                          style={addButtonStyle}
                          onClick={() => handleMoreClick(driver, id)}
                        >
                          More
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}</>}
    </>
  );
};

const mapStateToProps = (state) => {
  const drivers = state.root.drivers;
  return {
    drivers,
  };
};

const mapDispatchToProps = {
  fetchDriver: fetchDrivers,
  deleteDriverAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Driver);
