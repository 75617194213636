import React, { useEffect, useState } from 'react';
import CustomerDetailsPopup from './CustomerDetailsPopup';
import { fetchCustomers } from '../actions';
import { connect } from 'react-redux';
import './Customer.css';
import Loading from '../Loading/Loading';

const CustomerList = ({ customers, fetchCustomers }) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (customerId) => {
    setSelectedCustomerId(customerId);
  };

  useEffect(() => {
    fetchCustomers().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="list-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(customers || {}).map((customerId) => (
                <tr
                  key={customerId}
                  onClick={() => handleClick(customerId)}
                  className="customer-row"
                >
                  <td>{customers[customerId].name}</td>
                  <td>{customers[customerId].email}</td>
                  <td>{customers[customerId].phoneNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedCustomerId && (
            <CustomerDetailsPopup
              customerId={selectedCustomerId}
              customers={customers}
              onClose={() => setSelectedCustomerId(null)}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { customers } = state.root;
  return {
    customers,
  };
};

const mapDispatchToProps = {
  fetchCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
