import React from "react";
import CustomerDetails from "./CustomerDetails";
import DocumentDetails from "./DocumentDetails";
import BookingHistory from "./BookingHistory";
import RatingDetails from "./RatingDetails";
import "./Dashboard.css";

function Dashboard({customer, selectedKey}) {

  return (
    <div className="dashboard-container">
      <div className="customer-details">
        <CustomerDetails name={customer?.name} email={customer?.email} position={customer?.position} phoneNumber={customer?.phoneNumber} driverKey={selectedKey}/>
      </div>
      <div className="document-details">
        <DocumentDetails selectedKey={selectedKey} documents={customer?.documents} />
      </div>
      <div className="booking-history">
        <BookingHistory history={customer?.history} />
      </div>
      <div className="rating-details">
        <RatingDetails ratings={customer?.rating} />
      </div>
    </div>
  );
}

export default Dashboard;