import React from 'react';
import './Header.css';

const Header = ({ isOpen, toggleSidebar, enableSideBar }) => {
  return (
    <header className="header">
      <h1 className="title">Book My E-taxi</h1>
      {/* {enableSideBar ? (
          // <svg viewBox="0 0 24 24" width="30" height="30">
          //   {isOpen ? (
          //     <path fill="#333" d="M17.19,18L12.25,13.5L17.19,9l-1.44-1.5L9.81,12L15,18L17.19,18z" />
          //   ) : (
          //     <path fill="#333" d="M8.25,4.5L6.81,6l4.94,4.5L6.81,15l1.44,1.5L13.44,10.5L8.25,4.5z" />
          //   )}
          // </svg>
      ) : null} */}

      <div className="header-buttons">
        <button className="login-button">Help</button>
        <button className="signup-button">Contact Us</button>
      </div>
    </header>
  );
};

export default Header;
;