import React from 'react';
import Modal from 'react-modal';
import './CustomerDetailsPopup.css';

const CustomerDetailsPopup = ({ customerId, customers, onClose }) => {
  const customer = customers[customerId];

  return (
    <div className="popup-container">
      <Modal isOpen={true} onRequestClose={onClose} className="popup">
        <h2>{customer?.name}</h2>
        <p>Email: {customer?.email}</p>
        <p>Phone Number: {customer?.phoneNumber}</p>
        <h3>Ratings</h3>
        {Object.keys(customer?.rating).map((ratingId) => (
          <div key={ratingId}>
            <p>{customer?.rating[ratingId]?.customerName}</p>
            <p>{customer?.rating[ratingId]?.description}</p>
            <p>Rating: {customer?.rating[ratingId]?.rating}</p>
          </div>
        ))}
        <button onClick={onClose}>Close</button>
      </Modal>
    </div>
  );
};

export default CustomerDetailsPopup;
