import React, { useState } from 'react';
import './SignInSignUp.css';
import { auth } from '../firebase';
import {
  EXPIRATION_TIME_KEY,
  EXPIRY_DURATION,
  ID_TOKEN_KEY,
  CURRENT_CLAIM
} from '../constants';

const SignInSignUp = () => {

  const [activeTab, setActiveTab] = useState('admin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const getTabName = (active) => {
    if (activeTab === 'franchise') {
      return 'Franchise'
    } else if (activeTab === 'city_dealer') {
      return 'City Dealer'
    } else if (activeTab === 'admin') {
      return 'Admin'
    }
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      let role;
     if (activeTab === 'franchise') {
        role = 'franchise';
      } else if (activeTab === 'city_dealer') {
        role = 'city_dealer';
      } else if (activeTab === 'admin') {
        role = 'admin';
      }
     
      // Sign in with email and password
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      const { user } = userCredential;
      // Check if the signed-in user has the specified role
      if (user && user.email === email) {
        const idTokenResult = await user.getIdTokenResult();
        const { token: idToken } = idTokenResult;
        console.log('Authentication token:', idToken);
        const now = Date.now();
        const userRoles = idTokenResult.claims;
      
        if (idToken && userRoles && userRoles[role]) {
           auth.currentUser.getIdTokenResult().then((idTokenResult) => {
           const expiresIn = EXPIRY_DURATION * 24 * 60 * 60;
           idTokenResult.authTime = new Date().getTime();
           idTokenResult.expirationTime = idTokenResult.authTime + expiresIn * 1000;
           auth.currentUser.getIdToken(true);
        });
            localStorage.clear() 
            sessionStorage.setItem(ID_TOKEN_KEY, idToken);
            sessionStorage.setItem(EXPIRATION_TIME_KEY, now + EXPIRY_DURATION*24*60*60*1000);
            sessionStorage.setItem(CURRENT_CLAIM, role)
            console.log(`Signed in as ${role}`);
            window.location.replace('/welcome')
         
          // Perform any additional actions for the signed-in user with the specified role
        } else {
          console.log(`User is not authorized as ${role}`);
          // Handle unauthorized access
        }
      }
    } catch (error) {
      console.error('Failed to sign in:', error);
      // Handle sign-in error
    }
  };
  return (
    <div className="form-container-signin">
      <div className="signin-signup">
        <div className="tab-container">
        <div
            className={`tab ${activeTab === 'admin' ? 'active' : ''}`}
            onClick={() => handleTabChange('admin')}
          >
            Admin
          </div>
        
          <div
            className={`tab ${activeTab === 'city_dealer' ? 'active' : ''}`}
            onClick={() => handleTabChange('city_dealer')}
          >
            City Dealer
          </div>
          <div
            className={`tab ${activeTab === 'franchise' ? 'active' : ''}`}
            onClick={() => handleTabChange('franchise')}
          >
            Franchise
          </div>
        </div>
        <form className="form" onSubmit={handleSignIn}>
          <h2>{getTabName(activeTab)} Sign In</h2>
          <input
            className="form-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="form-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="form-button" type="submit">
            Sign In
          </button>
          <div className="divider"></div>
         
        </form>
       { activeTab === 'admin' && <p className="link">
            Forgot Password? <span onClick={()=> {
              window.location.replace('/reset-password-admin')
            }}>Click to reset</span>
          </p>}
      </div>
    </div>
  );
  
};

export default SignInSignUp;
