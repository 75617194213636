import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Trips from "./Trips/TripList";
import CustomerList from "./Customer/CustomerList";
import Sidebar from "./Sidebar";
import "./App.css";
import Driver from "./Driver/Driver";
import SignInSignUp from "./SignIn/SignInSignUp";
import { connect } from "react-redux/es";
import WelcomePage from "./Welcome/WelcomePage";
import { useState } from "react";
import { ID_TOKEN_KEY, CURRENT_CLAIM } from "./constants";
import CityDealer from './cityDealer/CityDealer';
import CityDealers from "./cityDealer/DisplayCityDealers";
import CreateUserWithFranchiseClaim from "./franchise/CreateFranchise";
import DisplayFranchise from "./franchise/DisplayFranchise";
import { useEffect } from "react";
import Profile from "./Profile";
import PasswordReset from "./PasswordReset";
import UpdatePassword from "./UpdatePassword";
import ManageCarRates from "./updatePrice/ManageCarRates";
// import DocumentSubmission from "./DocumentSubmission";

function App() {
  const [currentClaim] = useState(sessionStorage.getItem(CURRENT_CLAIM));
  const [enableSideBar, setEnableSideBar] = useState(
    sessionStorage.getItem(ID_TOKEN_KEY) ? true : false
  );

  return (
    <Router basename="/" >
      <div>
        <Sidebar
          enableSideBar={enableSideBar}
          currentClaim={currentClaim}
          setEnableSideBar={setEnableSideBar}
        />
        <div className="app blur">
          <Routes>
            {/* Route to serve the static landing page */}
            <Route path="/" element={<RedirectToLanding />} />

            {/* Routes for the React app */}
            <Route path="/admin" element={<SignInSignUp />} />
          <Route path="/" element={<SignInSignUp />} />
          <Route path="/reset-password-admin" element={<PasswordReset />} />
            {currentClaim === "admin" ? (
              <>
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/trips" element={<Trips />} />
                <Route path="/customers" element={<CustomerList />} />
                <Route path="/drivers" element={<Driver />} />
                <Route path='/createCityDealer' element ={<CityDealer/>}/>
                <Route path="/createfranchise" element={<CreateUserWithFranchiseClaim/>}/>
                <Route path='/cityDealer' element={<CityDealers/>}/>
                <Route path='/displayFranchise' element={<DisplayFranchise/>}/>
                <Route path='/profile' element={<Profile/>}/>
                <Route path='/update-password' element={<UpdatePassword/>}/>
                <Route path='manage-prices' element={<ManageCarRates/>}/>
                
              </>
            ) : currentClaim === "franchise" ? (
              <>
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/trips" element={<Trips />} />
                <Route path="/drivers" element={<Driver />} />
              </>
            ) : currentClaim === "city_dealer" ? (
              <>
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/createfranchise" element={<CreateUserWithFranchiseClaim />} />
                <Route path="/displayFranchise" element={<DisplayFranchise />} />
                <Route path="/drivers" element={<Driver />} />
                <Route path="/trips" element={<Trips />} />
              </>
            ) : null}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

// Component to redirect to the static landing page
function RedirectToLanding() {
  useEffect(() => {
    window.history.pushState({}, '', '/');
    window.location.href = "/landingPage/index.html";
  }, []);

  return null;
}

export default connect(null, null)(App);
