import {
  FETCH_DRIVER,
  FETCH_CUSTOMER,
  FETCH_TRIPS,
  FETCH_LOCATION,
  FETCH_CITY_DEALER,
  CLEAR_OBJECT,
  DELETE_CITY_DEALER,
  FETCH_FRANCHISE,
  FETCH_ADMINS
} from './types';

import {
  getDrivers,
  getCustomers,
  getTrips,
  getLocation,
  getCityDealers,
  getDriverForCityDealers,
  deleteDriver,
  deleteCityDealer as deleteCityDealerAPI,
  getTripsCityDealers,
  getTripsForFranchise,
  getDriverForFranchise,
  getFranchise,
  getAdmins,
  updatePassword
} from './axios';

import { CURRENT_CLAIM } from '../constants';
const currentClaim = sessionStorage.getItem(CURRENT_CLAIM);


export const fetchCustomers = () => {
  return async (dispatch) => {
    const { data: { data } } = await getCustomers();
    if (data) {
      dispatch({
        type: FETCH_CUSTOMER,
        payload: data
      });
    }
  };
};

export const deleteDriverAction = (id) => {
  return async (dispatch) => {
    await deleteDriver(id);
    dispatch({
      type: CLEAR_OBJECT,
      payload: 'drivers'
    });
    await fetchDrivers()(dispatch);
  };
};


export const deleteCityDealerAction = (id) => {
  return async (dispatch) => {
    await deleteCityDealerAPI(id);
    dispatch({
      type: CLEAR_OBJECT,
      payload: 'cityDealer'
    })
    fetchCityDealer()
  };
};

export const fetchDrivers = () => {
  return async (dispatch) => {
    let driverData = null;

    if (currentClaim === 'admin') {
      const { data: { data } } = await getDrivers();
      driverData = data;
    } else if (currentClaim === 'city_dealer') {
      const { data: { data } } = await getDriverForCityDealers();
      driverData = data;
    }else if (currentClaim === 'franchise') {
      const { data: { data } } = await getDriverForFranchise();
      driverData = data;
    }

    if (driverData) {
      dispatch({
        type: FETCH_DRIVER,
        payload: driverData
      });
    }
  };
};

export const fetchTrips = (dataa) => {
  // driverIds
  return async (dispatch) => {
    if (currentClaim === 'admin') {
    const { data: { data } } = await getTrips();
    if (data) {
      dispatch({
        type: FETCH_TRIPS,
        payload: data
      });
    }
    }else if(currentClaim === 'city_dealer'){
      
      const {data:{data}} = await getTripsCityDealers(dataa);
      if (data) {
        dispatch({
          type: CLEAR_OBJECT,
          payload: 'trips'
        });
        dispatch({
          type: FETCH_TRIPS,
          payload: data
        });
      }
    }else if(currentClaim === 'franchise'){
      
      const {data:{data}} = await getTripsForFranchise(dataa);
      if (data) {
        dispatch({
          type: CLEAR_OBJECT,
          payload: 'trips'
        });
        dispatch({
          type: FETCH_TRIPS,
          payload: data
        });
      }
    }
  };
};

export const fetchLocation = (driverKey) => {
  return async (dispatch) => {
    const { data: { data } } = await getLocation(driverKey);
    if (data) {
      dispatch({
        type: FETCH_LOCATION,
        payload: data
      });
    }
  };
};

export const fetchCityDealer = () => {
  return async (dispatch) => {
    const { data } = await getCityDealers();
    if (data) {
      dispatch({
        type: FETCH_CITY_DEALER,
        payload: data
      });
    }
  };
};
export const fetchFranchise = () => {
  return async (dispatch) => {
    const { data } = await getFranchise();
    if (data) {
      dispatch({
        type: FETCH_FRANCHISE,
        payload: data
      });
    }
  };
};

export const deleteCityDealer = (id) => { // Add this export
  return deleteCityDealerAction(id);
};

export const getAdminsAction = () => {
  return async (dispatch) => {
    const { data } = await getAdmins();
    if (data) {
      dispatch({
        type: FETCH_ADMINS,
        payload: data
      });
    }
  };
}

export const updatePasswordAction = (username, password) => {
  return updatePassword(username, password)
}