import React, { useState } from 'react';
import axios from 'axios'; // Ensure you have axios installed
import './UpdatePassword.css'; // Import your CSS file for styling
import { updatePasswordAction } from './actions';
import { connect } from 'react-redux';
const UpdatePassword = ({updatePasswordActionn}) => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    if (!email || !newPassword) {
      setError('Please enter both email and new password.');
      return;
    }

    try {
      updatePasswordActionn(email, newPassword).then(response => {
        setMessage(response.data.message);
        setError('');
      })
     
    } catch (error) {
      // setError('Failed to update password: ' + error.response?.data?.message || error.message);
    }
  };

  return (
    <div className="update-password-container">
      <form onSubmit={handlePasswordUpdate} className="update-password-form">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="new-password">New Password:</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <button type="submit" className="submit-button">Update Password</button>
        {message && <p className="message">{message}</p>}
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};



const mapDispatchToProps = {
  updatePasswordActionn: updatePasswordAction,
};

export default connect(null, mapDispatchToProps)(UpdatePassword);
